import { Image } from "react-bootstrap";
import Logo from "./../../assets/images/BlackLogo.svg";
import { LeftArrowSlide } from "../../compoments/icons/LeftArrowSlide";
import Footer from "./FooterStep";
import SkipLogin from "./SkipLogin";
// Reusable ArrowButton component
const ArrowButton = ({ direction, onClick }) => (
  <div className={`arrowButton pointer ${direction}`} onClick={onClick}>
    <LeftArrowSlide direction={direction} color={"black"} />
  </div>
);

const Step4 = ({ activeStep, setActiveStep }) => {
  return (
    <>
      <div className="Step4Splash">
        <section className="stepHow">
          <div className="leftArrow">
            <ArrowButton
              direction="right"
              onClick={() => setActiveStep(activeStep - 1)}
            />
          </div>
          <div
            style={{
              height: "auto",
              width: 700,
              background: "#fff",
              borderRadius: 32,
              border: "1px solid var(--cardBorder)",
            }}
            className="p-5 "
          >
            <h2 className="mt-4 text-center" style={{ fontWeight: 700 }}>
              Values & Promises
            </h2>
            <ul>
              <li>
                <span></span>Provide a helping hand from start to finish – act
                as a constant support for all parties involved in the sale.
              </li>
              <li>
                <span></span>Save agents time and money by streamlining the sale
                process.
              </li>
              <li>
                <span></span>Alleviate stress and anxiety for buyers and
                vendors.
              </li>
              <li>
                <span></span>Help agents stand out when conducting valuations –
                attractive package.
              </li>
              <li>
                <span></span>Reduce workload for agents and solicitors.
              </li>
              <li>
                <span></span>Ensure a clear timeline of sale process for buyers
                and vendors.
              </li>
              <li>
                <span></span>24/7 access to portal – allows users to complete
                tasks around their lives.
              </li>
              <li>
                <span></span>Reduce sale time significantly.
              </li>
              <li>
                <span></span>No 9-5 / bank holiday restrictions.
              </li>
            </ul>
            <div className="my-4 pt-4 text-center">
              <Image src={Logo} alt="Company Logo" width={80} />
            </div>
          </div>
          <div className="rightArrow">
            <ArrowButton
              direction="left"
              onClick={() => setActiveStep(activeStep + 1)}
            />
          </div>
        </section>
        <div>
          <Footer activeStep={activeStep} setActiveStep={setActiveStep} />
          <SkipLogin activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
      </div>
    </>
  );
};

export default Step4;
