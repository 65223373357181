import { Image } from "react-bootstrap";
import Logo from "./../../../../assets/images/logo.svg";
import { HomeIcon } from "../../../icons/HomeIcon";
import { ArrowDown } from "../../../icons/ArrowDown";
import { ProfileIcon } from "../../../icons/ProfileIcon";
import { LogoutIcon } from "../../../icons/LogoutIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import LogoutModal from "../../../modals/LogoutModal";
import { useSelector } from "react-redux";
import { DocIcon } from "../../../icons/DocIcon";

const SolicitorSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [logoutModal, setLogoutModal] = useState(false);

  const { addressList, documentList } = useSelector(
    (state) => state.authReducer
  );

  console.log({ addressList: addressList });
  console.log({ documentList: documentList });

  return (
    <>
      <aside className="sidebar">
        <div className="logo">
          <Image src={Logo} alt="logo" width={116} height={44} />
        </div>

        <ul>
          <li className="dropdown mb-3">
            <div
              className="d-flex align-items-center"
              style={{ width: "100%", height: "auto" }}
            >
              <div className="p-0">
                <HomeIcon />
              </div>
              <span className="activeAddressMenu">Manage Property</span>
            </div>

            <ArrowDown />
          </li>

          {addressList.map((item, index) => (
            <li
              key={index}
              title={item.name}
              className={
                location.pathname == `/solicitor/${item?._id}` ? "activeAgents" : ""
              }
              style={{ textTransform: "capitalize" }}
              onClick={() => navigate(`/solicitor/${item?._id}`)}
            >
              <div className={""}>
                <span>
                  <DocIcon />
                </span>
              </div>
              <span className="activeAddressAgent">{item.name}</span>
            </li>
          ))}

          <hr />

          <li onClick={() => navigate("/profile")}>
            <span className="me-3">
              <ProfileIcon />
            </span>
            My Profile
          </li>
          <li onClick={() => setLogoutModal(true)}>
            <span className="me-3">
              <LogoutIcon />
            </span>
            Logout
          </li>
        </ul>
      </aside>
      {logoutModal && (
        <LogoutModal show={logoutModal} onHide={() => setLogoutModal(false)} />
      )}
    </>
  );
};

export default SolicitorSidebar;
