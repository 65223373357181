import { Modal } from "react-bootstrap";
import { Logout } from "../icons/Logout";
import { useDispatch } from "react-redux";
import { Signout } from "../../redux/reducers/auth/action";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ show, onHide }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () =>{
    dispatch(Signout({navigate}));

  }
  return (
    <>
      <Modal
        show={show}
        className="uploadModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>Logout!</h4>
          <p>Are you sure you want to logout? Please confirm your action.</p>

          <div className="d-flex align-items-center mt-3 justify-content-center">
            <button className="btnGray" onClick={onHide}>
              Cancel
            </button>
            <button className="btnPrimary" onClick={handleLogout}>
              {" "}
              <span className="me-1">
                <Logout />
              </span>
              Logout
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutModal;
