import axios from "axios";
import { toast } from "react-toastify";

export const Login = ({ data, navigate }) => {
  // Define routes based on user roles
  const roleRoutes = {
    seller: "/seller",
    solicitor: "/solicitor",
    estateAgent: "/agent",
    buyer: "/buyer",
  };

  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response.data) {
        const { user, token, message } = response.data;
        dispatch({
          type: "LOGIN",
          payload: {
            user,
            token,
          },
        });

        try {
          const additionalResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/address/get-all`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (additionalResponse) {
            dispatch({
              type: "ADDRESS_LIST",
              payload: additionalResponse.data?.addresses || [], // Ensure payload is an array
            });
          } else {
            toast.error("Failed to fetch addresses.");
          }
        } catch (additionalError) {
          toast.error("Something went wrong while fetching additional data.");
        }

        const route = roleRoutes[user.role];
        if (route) {
          navigate(route);
        }
        toast.success(message || "Login successfully");
      } else {
        toast.error("Login failed, please try again");
      }
    } catch (error) {
      console.log(error.response.data);
      
      toast.error(error.response?.data?.message || "Invalid credentials");
    }
  };
};

export const Signout = ({ navigate }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "LOGOUT",
        payload: {
          user: {},
          token: "",
        },
      });
      dispatch({
        type: "DOC_LIST",
        payload: {},
      });
      dispatch({
        type: "ACTIVE_MENU",
        payload: null, // Ensure payload is an array
      });

      dispatch({ type: "ADDRESS_LIST", payload: [] });
      dispatch({ type: "DOCUMENTS_LIST", payload: [] });
      dispatch({ type: "DOC_LIST", payload: {} });
      dispatch({ type: "SELECTED_ADDRESS_ID", payload: '' });
      dispatch({ type: "ACTIVE_DOC", payload: {} });
      dispatch({ type: "ACTIVE_MENU", payload: null });




      navigate("/");
      toast.success("Logged out successfully");
    } catch (error) {
      toast.error("Please try again");
    }
  };
};
