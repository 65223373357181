import { Dropdown, Form, Image } from "react-bootstrap";
import { SearchIcon } from "./icons/SearchIcon";
import { GreenIcon } from "./icons/GreenIcon";
import { RedIcon } from "./icons/RedIcon";
import Avatar from './../assets/images/Avatar.png'
import { ArrowDownMenu } from "./icons/ArrowDownMenu";
import { useEffect, useState } from "react";
import LogoutModal from "./modals/LogoutModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { BlueIcon } from "./icons/BlueIcon";

const HeaderDashboard = ({type, page}) => {
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { userData, token } = useSelector(
    (state) => state.authReducer
  );


  console.log({type});
  
  
  useEffect(() => {
    // Fetch user profile data on component mount
    const fetchUserProfile = async () => {
      if (!token) {
        toast.error("Authentication token is missing.");
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/user-detail`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const user = response.data;
          dispatch({
            type: 'USER_PROFILE',
            payload: user,
          });
          
        } else {
          toast.error("Failed to fetch user profile data.");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error.response || error.message);
        toast.error("Failed to load user profile");
      }
    };

    fetchUserProfile();
    return () => {
      // If you need to handle cleanup (e.g., cancel request, reset states)
    };
  }, [dispatch, token]);


  console.log({type});
  
  return (
    <>
      <div className="customCard">
        <div className="pageHeader">
          <div className="position-relative">
            <Form.Control type="text" placeholder="Search" autoComplete="off" />
            <span className="fieldIcon">
              <SearchIcon />
            </span>
          </div>
          <div className="d-flex align-items-center">
            {(type === "Buyer" ||type === "Seller") && (
              <>
                <button className="uploadPending">
                  <span>
                    <RedIcon />
                  </span>
                  Upload Pending
                </button>
                <button className="uploaded mx-2">
                  <span>
                    <GreenIcon />
                  </span>
                  Uploaded
                </button>
                <button className="approved mx-2">
                  <span>
                    <BlueIcon />
                  </span>
                  Approved
                </button>
              </>
            )}
            <Dropdown className="profileDropdown">
              <Dropdown.Toggle id="dropdown-basic" className="">
                <Image src={userData?.image || Avatar} alt="" />
                <span className="">
                  <ArrowDownMenu />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={() => navigate(`/profile`)}
                >
                  Profile
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => navigate(`/account-setting`)}
                >
                  Settings
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => navigate(`/help-center`)}
                >
                  Help Center{" "}
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={() => setLogoutModal(true)}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {logoutModal && (
        <LogoutModal show={logoutModal} onHide={() => setLogoutModal(false)} />
      )}
    </>
  );
};

export default HeaderDashboard;
