import { Form, Image } from "react-bootstrap";
import Logo from "./../../assets/images/BlackLogo.svg";
import { useState } from "react";
import { LeftArrowSlide } from "../../compoments/icons/LeftArrowSlide";
import { useNavigate } from "react-router-dom";
import { Login } from "../../redux/reducers/auth/action";
import { useDispatch } from "react-redux";
// Reusable ArrowButton component

const ArrowButton = ({ direction, onClick }) => (
  <div className={`arrowButton pointer ${direction}`} onClick={onClick}>
    <LeftArrowSlide direction={direction} color="black" />
  </div>
);

const roleText = {
  agent: "Login as Estate Agent",
  seller: "Login as Seller",
  solicitor: "Login as Solicitor",
  buyer: "Login as Buyer",
};



const Step6 = ({ activeStep, setActiveStep, userRole }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading]= useState(false)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = () => {
    setLoading(true)
    const data = {
      email: formData.email,
      password: formData.password,
      role: userRole
    };
    dispatch(Login({ data, navigate })); 
    setLoading(true)  
  };
  return (
    <>
      <div className="Step6Splash">
        <section className="stepHow">
          <div className="leftArrow">
            <ArrowButton
              direction="right"
              onClick={() => setActiveStep(activeStep - 1)}
            />
          </div>
          <div
            style={{
              height: "auto",
              width: 600,
              background: "#fff",
              borderRadius: 32,
              border: "1px solid var(--cardBorder)",
            }}
            className="p-5"
          >
            <div className="text-center">
              <Image src={Logo} alt="Company Logo" width={170} />
            </div>

            <h2 className="mt-5 text-center" style={{ fontWeight: 700 }}>
              {roleText[userRole]}
            </h2>

            <Form className="px-3">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username here"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password here"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Form.Group>

              <button
                type="button"
                className="w-100 mt-3 btnPrimary"
                onClick={ handleLogin}
              >
                LOGIN
                {/* {loading? "LOADING..." :"LOGIN"} */}
              </button>
            </Form>
          </div>
          <div className="rightArrow" style={{ visibility: "hidden" }}>
            <ArrowButton
              direction="left"
              onClick={() => setActiveStep(activeStep + 1)}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default Step6;
