import { Image } from "react-bootstrap";
import PDF from "../assets/images/PDF.png";
import { EyeIcon } from "./icons/EyeIcon";
import { DeleteIcon } from "./icons/DeleteIcon";
import { DownloadIcon } from "./icons/DownloadIcon";
import { useState } from "react";
import DeleteFileModal from "./modals/DeleteFileModal";
import DownloadFileModal from "./modals/DownloadFileModal";
import PreviewFileModal from "./modals/PreviewFileModal";
import moment from "moment";

const UploadFile = ({
  title,
  handleFileChange,
  selectedFile,
  documents,
  setSelectedFile,
  setDocumentID,
  deleteDocument,
  loading,
  btnLoading
}) => {
  const [previewModal, setPreviewModal] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [downloadModal, setDownloadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setDeleteModal(true);
    setDocumentID(file?._id);
  };

  const handleDownload = async (fileUrl, fileName) => {
    if (!fileUrl) {
      console.error("File URL is undefined.");
      return;
    }

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const aTag = document.createElement("a");
      aTag.href = url;
      aTag.setAttribute("download", fileName);
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
      // Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
      setDownloadModal(true)
    } catch (error) {
      setDownloadModal(false)

      console.error("Error downloading the file:", error);
    }
  };

  return (
    <>
      <div className="pageBody">
        <div className="heading">{title}</div>

        {loading ? (
          <div className="py-5 text-center">Loading Please Wait...</div>
        ) : (
          <>
            {documents?.documents &&
              documents?.documents.length > 0 &&
              documents?.documents.map((file, index) => (
                <div key={index} className="uploadedFile">
                  <div className="listContainer">
                    <div className="d-flex align-items-center">
                      <div className="pdfImage">
                        <Image src={PDF} alt="" width={32} />
                      </div>
                      <div className="pdfDetail">
                        <div>
                          <strong>File Name: </strong>
                          <span>{file?.originalName}</span>
                        </div>
                        <div>
                          <strong>Upload Date: </strong>
                          <span>
                            {moment(file?.updatedAt).format("DD/MM/YY")}
                          </span>
                        </div>
                        <div>
                          <strong>Upload Time: </strong>
                          <span>
                            {moment(file?.updatedAt).format("hh:mm A")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="pdfButton">
                      <button
                        className="previewBTN"
                        onClick={() => {
                          setFile(file.url);
                          setFileName(file.originalName);
                          setPreviewModal(true);
                        }}
                      >
                        <span>
                          {" "}
                          <EyeIcon />
                        </span>
                        Preview
                      </button>
                      <button
                        className="deleteBTN mx-2"
                        onClick={() => handleDeleteClick(file)}
                      >
                        <span>
                          <DeleteIcon />
                        </span>
                        Delete
                      </button>
                      <button
                        className="downloadBTN text-decoration-none"
                        onClick={() =>
                          handleDownload(file.url, file.originalName)
                        }
                      >
                        <span>
                          <DownloadIcon />
                        </span>
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>

      <div className="pageBody">
        <div className="uploadSection">
          <div className="position-relative">
            <Image src={PDF} alt="" />
            <p>Upload PDF</p>
            <input
              type="file"
              className="uploadField"
              accept=".pdf"
              multiple
              onChange={handleFileChange}
            />
          </div>
        </div>
      </div>

      {deleteModal && (
        <DeleteFileModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          handleDeleteFile={deleteDocument}
          btnLoading={btnLoading}
        />
      )}
      {downloadModal && (
        <DownloadFileModal
          show={downloadModal}
          onHide={() => setDownloadModal(false)}
          btnLoading={btnLoading}
        />
      )}
      {previewModal && (
        <PreviewFileModal
          show={previewModal}
          onHide={() => setPreviewModal(false)}
          file={file}
          download={() => handleDownload(file, fileName)}
          title={title}
          btnLoading={btnLoading}
        />
      )}
    </>
  );
};

export default UploadFile;
