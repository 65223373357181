import React, {  useState } from "react";
import { Image } from "react-bootstrap";
import Logo from "./../../../../assets/images/logo.svg";
import { HomeIcon } from "../../../icons/HomeIcon";
import { ArrowDown } from "../../../icons/ArrowDown";
import { ProfileIcon } from "../../../icons/ProfileIcon";
import { HelpIcon } from "../../../icons/HelpIcon";
import { LogoutIcon } from "../../../icons/LogoutIcon";
import { RedIconSide } from "../../../icons/RedIconSide";
import { GreenIconSide } from "../../../icons/GreenIconSide";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutModal from "../../../modals/LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { BlueIcon } from "../../../icons/BlueIcon";
import { VerifiedIcon } from "../../../icons/VerifiedIcon";


const SidebarItem = ({
  label,
  icon,
  status,
  href,
  navigate,
  location,
  dispatch,
  data,
}) => (
  <li
    className={location.pathname === href ? "active" : ""}
    onClick={() => {
      navigate(href);
      dispatch({
        type: "ACTIVE_DOC",
        payload: data, // Ensure payload is an array
      });
    }}
  >
    <div className={status}>
      <span>{icon}</span>
    </div>
    {label}
  </li>
);

const SellerSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const location = useLocation();

  const [logoutModal, setLogoutModal] = useState(false);
  const [openDropdownIndex] = useState(null); // Manage which dropdown is open

  const { addressList, documentList, token, activeMenu } = useSelector(
    (state) => state.authReducer
  );

  const handleDropdownToggle = async (addressId, index) => {
    if (activeMenu !== index) {
      dispatch({ type: "SELECTED_ADDRESS_ID", payload: null });
      dispatch({ type: "ACTIVE_MENU", payload: null });
    }
    dispatch({
      type: "SELECTED_ADDRESS_ID",
      payload: addressId || {},
    });
    try {
      // Make the additional API call to fetch addresses
      const docResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/address/doc-status/address-id/${addressId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (docResponse) {
        dispatch({
          type: "DOC_LIST",
          payload: docResponse.data || {},
        });
        dispatch({
          type: "ACTIVE_MENU",
          payload: openDropdownIndex === index ? null : index, // Ensure payload is an array
        });
        // navigate('/seller/id')
      } else {
        toast.error("Failed to fetch addresses.");
      }
    } catch (additionalError) {
      toast.error("Something went wrong while fetching additional data.");
    }
  };


  const docTypesArray = documentList?.docTypesStatus 
  ? Object.entries(documentList.docTypesStatus).map(([label, status]) => ({
      label,
      icon: status== 'pending' ? <RedIconSide /> : status =='upload'? <GreenIconSide />: <VerifiedIcon/>,
      status: status== 'pending' ? "error": status =='upload' ?"success" : "verified", // Set status as "success" or "error"
      href: `/seller/${label.replace(/[\s/]/g, "-").toLowerCase()}`, 
    }))
  : [];

  console.log({docTypesArray});


  return (
    <>
      <aside className="sidebar">
        <div className="logo">
          <Image src={Logo} alt="logo" width={116} height={44} />
        </div>

        <ul>
          {addressList &&
            addressList.length > 0 &&
            addressList.map((address, index) => (
              <React.Fragment key={index}>
                <li
                  className="dropdown mb-3"
                  onClick={() => handleDropdownToggle(address?._id, index)}
                  style={{ minHeight: 54 }}
                >
                  <div className="d-flex align-items-center me-0 w-100">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "100%", height: "auto" }}
                    >
                      <div className="p-0">
                        <HomeIcon />
                      </div>
                      <span
                      className="activeAddressMenu"
                       
                      >
                        {address?.name}
                      </span>
                    </div>
                    <ArrowDown
                      className={openDropdownIndex === index ? "rotate" : ""}
                    />
                  </div>
                </li>

                {activeMenu === index && (
                  <ul className="dropdown-content mb-4">
                    {docTypesArray && docTypesArray?.length> 0 && docTypesArray.map((item, itemIndex) => (
                      <SidebarItem
                        key={itemIndex}
                        label={item.label}
                        icon={item.icon}
                        status={item.status}
                        href={item.href}
                        navigate={navigate}
                        location={location}
                        dispatch={dispatch}
                        data={item}
                      />
                    ))}
                  </ul>
                )}
              </React.Fragment>
            ))}

          <hr className="mt-4" />

          <li onClick={() => navigate("/profile")}>
            <span className="me-3">
              <ProfileIcon />
            </span> 
            My Profile
          </li>
          <li onClick={() => navigate("/help-center")}>
            <span className="me-3">
              <HelpIcon />
            </span>
            Help Center
          </li>
          <li onClick={() => setLogoutModal(true)}>
            <span className="me-3">
              <LogoutIcon />
            </span>
            Logout
          </li>
        </ul>
      </aside>
      {logoutModal && (
        <LogoutModal show={logoutModal} onHide={() => setLogoutModal(false)} />
      )}
    </>
  );
};



export default SellerSidebar;
