import { Image } from "react-bootstrap";
import Logo from "./../../assets/images/BlackLogo.svg";
import { LeftArrowSlide } from "../../compoments/icons/LeftArrowSlide";
import Footer from "./FooterStep";
const ArrowButton = ({ direction, onClick }) => (
  <div className={`arrowButton pointer ${direction}`} onClick={onClick}>
    <LeftArrowSlide direction={direction} color="black" />
  </div>
);

const Step5 = ({ activeStep, setActiveStep, setUserRole }) => {

  const handleUserLoginRole = (role)=>{
    setUserRole(role);
    setActiveStep(activeStep + 1)
  }
  return (
    <>
      <div className="Step5Splash">
        <section className="stepHow">
          <div className="leftArrow">
            <ArrowButton
              direction="right"
              onClick={() => setActiveStep(activeStep - 1)}
            />
          </div>
          <div
            style={{
              height: "auto",
              width: 600,
              background: "#fff",
              borderRadius: 32,
              border: "1px solid var(--cardBorder)",
            }}
            className="p-5 text-center"
          >
            <div className="px-3">
              <div className="">
                <Image src={Logo} alt="Company Logo" width={170} />
              </div>

              <h2 className="mt-5" style={{ fontWeight: 700 }}>
                Login
              </h2>

              <div className="mb-3">
                <button className="w-100 btnOutline" onClick={()=>handleUserLoginRole('estateAgent')}>Login as an Agent</button>
              </div>
              <div className="mb-3">
                <button className="w-100 btnOutline" onClick={()=>handleUserLoginRole('solicitor')}>
                  Login as a Solicitor
                </button>
              </div>
              <div className="mb-3">
                <button className="w-100 btnOutline" onClick={()=>handleUserLoginRole('buyer')}>Login as a Buyer</button>
              </div>
              <div className="mb-3">
                <button className="w-100 btnOutline" onClick={()=>handleUserLoginRole('seller')}>Login as a Seller</button>
              </div>
            </div>
          </div>
          <div className="rightArrow" style={{ visibility: "hidden" }}>
            <ArrowButton
              direction="left"
              onClick={() => setActiveStep(activeStep + 1)}
            />
          </div>
        </section>
        <div>
          <Footer activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
      </div>
    </>
  );
};

export default Step5;
