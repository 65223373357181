import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import SplashScreen from "./splash";
import HelpCenter from "./seller/HelpCenter";
import Profile from "./seller/Profile";


import AddressDocListSolicitor from "./solicitor/AddressDocListSolicitor";
import PrivateRoute from "./route/PrivateRoute";
import AccountSetting from "./buyer/AccountSetting";
import ID from "./buyer/ID";
import BuyerDashboard from "./buyer/Dashboard";
import SellerDashboard from "./seller/Dashboard";
import IDSeller from "./seller/ID";
import AgentDashboard from "./agent/DashboardAgent";
import AddressDocList from "./agent/Address";
import SolicitorDashboard from "./solicitor/SolicitorDashboard";
import NotFound from "./NotFound";

const AppRouter = () => {
  return (
    <Router>
     <Routes>
        {/* Public Routes */}
        <Route path="/" element={<SplashScreen />} />

        {/* Seller Routes */}
        <Route path="/" element={<PrivateRoute allowedRoles={['seller']} />}>
          <Route path="/seller" element={<SellerDashboard />} />
          <Route path="/seller/:id" element={<IDSeller />} />
        </Route>

        {/* Buyer Routes */}
        <Route path="/" element={<PrivateRoute allowedRoles={['buyer']} />}>
          <Route path="/buyer" element={<BuyerDashboard />} />
          <Route path="/buyer/:link" element={<ID />} />
        </Route>

        {/* Agent Routes */}
        <Route path="/" element={<PrivateRoute allowedRoles={['estateAgent']} />}>
          <Route path="/agent" element={<AgentDashboard />} />
          <Route path="/agent/:id" element={<AddressDocList />} />
        </Route>

        {/* Solicitor Routes */}
        <Route path="/" element={<PrivateRoute allowedRoles={['solicitor']} />}>
          <Route path="/solicitor" element={<SolicitorDashboard />} />
          <Route path="/solicitor/:id" element={<AddressDocListSolicitor />} />
        </Route>

        {/* Fallback Route */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/account-setting" element={<AccountSetting />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
