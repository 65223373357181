import PageLayout from "../compoments/layout/layout";
import HeaderDashboard from "../compoments/Header";
import UploadFile from "../compoments/UploadFile";
import { useEffect, useState } from "react";
import UploadFileModal from "../compoments/modals/UploadFileModal";
import BuyerSidebar from "../compoments/layout/sidebar/buyer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const ID = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentID, setDocumentID] = useState("");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const { token, selectedAddressID, activeDoc, activeMenu, addressList } =
    useSelector((state) => state.authReducer);
  const [openDropdownIndex] = useState(null); // Manage which dropdown is open

  console.log({ addressList });

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const invalidFiles = files.filter(
      (file) => file.type !== "application/pdf"
    );

    if (invalidFiles.length > 0) {
      setError("Please upload only PDF files.");
      e.target.value = ""; // Clear the input field
    } else {
      setError(null);
      setSelectedFiles(files);
      if (files.length > 0) {
        setUploadModal(true);
      }
    }
  };

  const fetchDocuments = async () => {
    if (!selectedAddressID || !activeDoc) {
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/doc/get-document/list`,
        {
          params: {
            addressId: selectedAddressID,
            docType: activeDoc.label,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        setDocuments(response.data); // Set the documents to the state
        dispatch({
          type: "DOCUMENTS_LIST",
          payload: response.data.documents || [],
        });
        setLoading(false);
      } else {
        setLoading(false);
        setError("No data found in the response");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching documents:", error);
      setError("An error occurred while fetching documents");
    } finally {
      setLoading(false);
      console.log("finally");
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [selectedAddressID, activeDoc, token]);

  console.log({ activeDoc });

  const handleDropdownToggle = async () => {
    try {
      // Make the additional API call to fetch addresses
      const docResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/address/doc-status/address-id/${addressList[0]?._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (docResponse) {
        dispatch({
          type: "DOC_LIST",
          payload: docResponse.data || {},
        });
      } else {
        toast.error("Failed to fetch documents.");
      }
    } catch (additionalError) {
      toast.error("Something went wrong while fetching additional data.");
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append(activeDoc.label, selectedFiles[i]);
    }

    formData.append("addressId", selectedAddressID);
    setBtnLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/doc/upload-multi-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);
      setUploadModal(false);
      fetchDocuments();
      setBtnLoading(false);
      handleDropdownToggle();
      console.log("Upload Success:", response.data);
    } catch (error) {
      setBtnLoading(false);
      console.error("Upload Error:", error);
    }
  };

  const deleteDocument = async () => {
    if (!selectedAddressID || !documentID) {
      return;
    }
    setBtnLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/doc/single-doc`,
        {
          params: {
            addressId: selectedAddressID,
            docId: documentID,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response) {
        console.log("Document deleted successfully");
        toast.success(response.data.message);
        fetchDocuments(); // Or remove the deleted document from the state
        setBtnLoading(false);
        handleDropdownToggle()
      } else {
        setError("Failed to delete the document");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      setError("An error occurred while deleting the document");
      toast.success(error);
      setBtnLoading(false);
    } finally {
      setBtnLoading(false);
      console.log("Delete operation complete");
    }
  };

  console.log({ documentID: documentID });
  console.log({ selectedAddressID: selectedAddressID });

  return (
    <PageLayout>
      <section className="pageWrapper">
        <div className="sidebarContent">
          <BuyerSidebar />
        </div>
        <section className="mainContent">
          <HeaderDashboard type="Buyer" />
          <div className="customCard">
            <UploadFile
              title={activeDoc?.label}
              handleFileChange={handleFileChange}
              selectedFile={selectedFiles}
              documents={documents}
              setDocumentID={setDocumentID}
              deleteDocument={deleteDocument}
              loading={loading}
              btnLoading={btnLoading}
            />

            {uploadModal && (
              <UploadFileModal
                show={uploadModal}
                onHide={() => setUploadModal(false)}
                selectedFile={selectedFiles}
                handleUpload={handleUpload}
                btnLoading={btnLoading}
              />
            )}
          </div>
        </section>
      </section>
    </PageLayout>
  );
};

export default ID;
